<div *ngIf="agente$ | async as agente; else loading">
   <nav class="navbar navbar-inverse navbar-static-top">
      <div class="container-nav">
         <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
               aria-expanded="false" aria-controls="navbar">
               <span class="sr-only">Toggle navigation</span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
            </button>
            <span class="navbar-brand">
               <span style="color: white">
                  <img src="public/img/logoKerberus_.png" title="Expandir Menu">
               </span>
            </span>
         </div>
         <div id="navbar" class="navbar-collapse collapse" *ngIf="operacionEncurso$ | async as operacion">
            <ul class="nav navbar-nav">
               <li style="padding-top: 10px">

                  <app-agent-controller [agente]="agente" [callStatus]="callStatus"
                     [disabled]="operacion.inProgress || !agente.canChangeSkill" (contacto)="cargarContacto($event)"
                     (directorio)="cargarFormularioContacto($event)" (cambioSkill)="cambiarModo($event, agente)">
                  </app-agent-controller>

               </li>
               <li class="li-stat">
                  TMO General
                  <span class="badge">
                     {{agente.states | totalAttendedCalculator | date: 'HH:mm:ss'}}
                  </span>
               </li>
            </ul>
            <ul class="nav navbar-nav navbar-right" style="padding-top: 10px">
               <li>
                  <button type="button" class="btn btn-default" (click)="cerrarSesion()"><i class="fa fa-sign-out"></i>
                     Salir</button>
               </li>
            </ul>
            <ul class="nav navbar-nav navbar-right" style="padding-top: 10px; margin-right: 10px">
               <li>
                  <app-schedule [agente]="agente" (accion)="ejecutarAccionAgenda($event, agente.estado)">
                  </app-schedule>
               </li>
            </ul>
            <ul class="nav navbar-nav navbar-right" style="padding-top: 10px; margin-right: 10px">
               <li>
                  <div class="btn-group" role="group" aria-label="..." *ngIf="!!agente">

                     <button type="button" class="btn btn-default" *ngIf="incallStateTime$ | async as incallTime">
                        <ng-container *ngIf="channelSnapshot$ | async as channel">
                           {{(channel?.callerid.name || "") + " " + (channel?.callerid.number || "")}}
                        </ng-container>
                        <i class="fa fa-volume-control-phone"></i> : {{incallTime | date:'HH:mm:ss'}}
                     </button>
                     <div class="btn-group" role="group" (click)="cargarMotivosPausa()"
                        *ngIf="!agente?.estado?.includes('PAUSE')">
                        <button type="button" class="btn btn-warning dropdown-toggle"
                           [disabled]="agente.estado === 'OFFLINE' || !agente.canPause || operacion.inProgress"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <i class="fa fa-pause" style="margin-right: 5px;"></i> <i class="fa fa-caret-down"></i>
                        </button>
                        <ul class="dropdown-menu">
                           <ng-container *ngIf="pauseReason$ | async as pauseReason; else loadingReasons">
                              <li *ngFor="let motivo of pauseReason">
                                 <a (click)="Pausa(agente, motivo.reason, 'pause')">{{motivo.reason}}</a>
                              </li>
                           </ng-container>
                           <ng-template #loadingReasons>
                              <li class="text-center">
                                 <i class="fa fa-spinner fa-spin text-primary" style="margin: 10px 0;"></i>
                              </li>
                           </ng-template>
                        </ul>
                     </div>
                     <!-- Pause controls  -->
                     <ng-container *ngIf="pauseStateTime$ | async as pauseTime">
                        <button type="button" class="btn btn-warning" (click)="Pausa(agente, 'Regresa', 'unpause')"
                           *ngIf="agente?.estado?.includes('PAUSE')" [disabled]="operacion.inProgress">
                           <i class="fa fa-play"></i> {{pauseTime | date:'HH:mm:ss' }}
                        </button>
                     </ng-container>
                     <button type="button"
                        [disabled]="!agente?.estado?.includes('BUSY') || !agente.canHangup || operacion.inProgress"
                        class="btn btn-danger" (click)="colgarLlamada(agente,'')" title="Colgar llamada">
                        <span class="glyphicon glyphicon-phone-alt"></span>
                     </button>
                  </div>
               </li>
            </ul>
         </div>
         <!--/.nav-collapse -->
      </div>
   </nav>
   <!-- Page Content -->
   <div class="panel" style="margin: 20px; margin-bottom: 65px; padding: 15px"
      *ngIf="currentTime$ | async as fechaActual">
      <div class="text-left visible-sm visible-xs">
         <h4 class="text-info">{{ fechaActual | toDate | amDateFormat: 'dddd D [de] MMMM, h:mm a'}}</h4>
         <hr>
      </div>
      <div class="row">
         <div class="col-md-6">
            <div class="text-muted" style="font-weight: 500; margin-bottom: 10px;"><i class="fa fa-rocket"></i>
               Operaciones</div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs" id="tabContacto" name="tabContacto" role="tablist">
               <li role="presentation" class="active">
                  <a href="#Marcador" aria-controls="Marcador" role="tab" data-toggle="tab">
                     <i class="fa fa-tty"></i>
                     Marcadores</a>
               </li>
               <li role="presentation">
                  <a href="#Contacto" aria-controls="Contacto" role="tab" data-toggle="tab">
                     <i class="fa fa-address-card-o"></i> Datos de Contacto
                  </a>
               </li>
               <li role="presentation" *ngIf="channelSnapshot$ | async">
                  <a href="#meta-info" aria-controls="meta-info" role="tab" data-toggle="tab">
                     <i class="fa fa-cloud-download"></i> Meta info
                  </a>
               </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content" style="margin-top: 10px;">
               <div role="tabpanel" class="tab-pane fade in active" id="Marcador">
                  <app-marcador [agente]="agente" [callStatus]="callStatus" (contacto)="cargarContacto($event)">
                  </app-marcador>
               </div>
               <div role="tabpanel" class="tab-pane fade" id="Contacto">
                  <app-contacto [contacto$]="idContacto$.asObservable()" [directorio$]="directorio$.asObservable()"
                     (updateChannel)="actualizaCanalAgente($event)"></app-contacto>
               </div>
            </div>
            <!-- Tab panes -->

            <hr>
            <div style="font-size: 18px; margin-top: 15px;"><i class="fa fa-bar-chart-o"></i> Estadísticas de atención
            </div>
            <small class="text-muted">Información de rendimiento del agente</small>
            <table class="table table-striped table-bordered " style="font-size: 12px; margin-top: 10px;">
               <thead style="background-color: #ebebeb">
                  <tr>
                     <th colspan="2">Cola</th>
                     <th class="text-center">Skill</th>
                     <th class="text-center" colspan="2">Inbound</th>
                     <th class="text-center" colspan="2">Outbound</th>
                     <th class="text-center" colspan="2">Dialer</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let stat of agente.states">
                     <td>{{stat.queuename}}</td>
                     <td>
                        <i class="fa fa-star" style="color: red; margin-right: 5px;"
                           *ngIf="stat.bound_channel_id !== ''"></i>
                        <span class="label" [ngClass]="'label-' + (stat.status | statusColor)">{{stat?.status |
                           statusName}}</span>
                     </td>
                     <td class="text-center">{{stat.penalty | statsSkillsNames}}</td>
                     <td class="text-center">{{stat.inbound}}</td>
                     <td class="text-center">{{stat.tmo_inbound | tmoCalculator: stat.inbound | date:'HH:mm:ss'}}</td>
                     <td class="text-center">{{stat.outbound}}</td>
                     <td class="text-center">{{stat.tmo_outbound | tmoCalculator: stat.outbound | date:'HH:mm:ss'}}</td>
                     <td class="text-center">{{stat.dialer}}</td>
                     <td class="text-center">{{stat.tmo_dialer | tmoCalculator: stat.dialer | date:'HH:mm:ss'}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="col-md-6">
            <div class="text-right hidden-sm hidden-xs">
               <h4 class="text-info" style="margin-bottom: 0;">
                  {{fechaActual | toDate | amDateFormat: 'dddd D [de] MMMM, h:mm a'}}
               </h4>
            </div>
            <span class="text-muted"><strong> <i class="fa fa-keyboard-o"></i> Tipificación de Llamada</strong></span>
            <br>
            <tipificacion-presenter [agente]="agente" [channelSnapshot]="channelSnapshot$ | async">
            </tipificacion-presenter>
         </div>
      </div>
   </div>

   <div class="modal fade" tabindex="-1" role="dialog" id="xferConfirm">
      <div class="modal-dialog modal-sm" role="document">
         <div class="modal-content">
            <div class="modal-body text-center">
               Desea transferir esta llamada a <br><strong> XXX ?</strong>
            </div>
            <div class="modal-footer">
               <button class="btn btn-sm btn-default" data-dismiss="modal">Cancelar</button>
               <button class="btn btn-sm btn-primary" (click)="ejecutarXfer(agente)">Aceptar</button>
            </div>
         </div>
      </div>
   </div>

   <div class="modal fade" tabindex="-1" role="dialog" id="modalWait_kerberus">
      <div class="modal-dialog modal-sm" role="document">
         <div class="modal-content">
            <div class="modal-body text-center">
               <img src="public/img/ajax-loader.gif" alt="Un momento..."><br>
               Un momento por favor...
            </div>
         </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
   </div><!-- /.modal -->

   <div class="modal fade" tabindex="-1" role="dialog" id="modalWait_kerberus_call">
      <div class="modal-dialog modal-sm" role="document">
         <div class="modal-content">
            <div class="modal-body text-center">
               <img src="public/img/ajax-loader.gif" alt="Un momento..."><br>
               Ejecutando la llamada...
            </div>
         </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
   </div><!-- /.modal -->
</div>
<ng-template #loading>
   <div class="panel panel-body text-center text-muted" style="margin: 40px; padding: 120px 0;">
      <ng-container *ngIf="noAgent$ | async; else waiting">
         <i class="fa fa-4x fa-plug" style="color: rgb(206, 206, 206); margin-bottom: 10px;"></i>
         <h5>Parece que este agente no esta en ninguna campaña de callcenter.</h5>
         <small>Verifica con el supervisor o manager de callcenter. <strong><a href="javascript:void(0)"
                  (click)="cerrarSesion()">Regresar</a></strong></small>
      </ng-container>
      <ng-template #waiting>
         <i class="fa fa-2x fa-spinner fa-spin text-primary"></i><br>
         <h5>Cargando datos del agente...</h5>
      </ng-template>
   </div>
</ng-template>